<template>
    <div class="page">
        <div class="banne-logo">
            <img src="./banner-logo.png" class="banner">
        </div>
        <div class="ms-login">
            <el-form :model="ruleForm"
                     :rules="rules"
                     ref="ruleForm"
                     class="form">
                <el-row>
                    <div class="images" v-if="isScan">
                        <div style="text-align: center">
<!--                            <h4>扫码登录</h4>-->
                            <div class="logo-svg">
<!--                                <img src="./logo.svg">-->
                                <h2>扫码登录</h2>
                            </div>
                            <el-image class="img" :src="items.qrCodeUrl" @click="getData"></el-image>
                            <div style="padding-bottom: 30px">使用微信手机版扫描登录</div>
                            <el-button type="text" @click="isScan=false">帐号密码登录</el-button>
                        </div>
                    </div>
<!--                    <el-col :span="2" class="lines">-->
<!--                    </el-col>-->
                    <div v-else>
                        <div class="logo-svg">
                            <img src="./logo.svg">
                            <h2>用户登陆</h2>
                        </div>
                        <el-form-item prop="username">
                            <el-input v-model="ruleForm.username"
                                      size="small"
                                      prefix-icon="el-icon-s-custom"
                                      placeholder="请输入用户名"></el-input>
                        </el-form-item>
                        <el-form-item prop="password">
                            <el-input v-model="ruleForm.password"
                                      size="small"
                                      show-password
                                      prefix-icon="el-icon-lock"
                                      @keyup.enter.native="submitForm('ruleForm')"
                                      placeholder="请输入登录密码"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary"
                                       size="medium"
                                       @click="submitForm('ruleForm')"
                                       :loading="loading">登 录
                            </el-button>
                        </el-form-item>
                        <el-button type="text" @click="isScan=true">扫码登录</el-button>
                    </div>
                </el-row>
            </el-form>
        </div>
        <div class="footer">
            <p>CopyRight &copy;2021; 上海佐相信息科技有限公司</p>
            <p>Build: {{$store.state.app.version||'v1.0.0'}}</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'zk-login',
        props: ['title'],
        data() {
            return {
                loading: false,
                ruleForm: {
                    username: '',
                    password: '',
                },
                isScan:true,
                rules: {
                    username: [
                        {required: true, message: '请输入用户名', trigger: 'blur'},
                        {min: 2, max: 16, message: '长度在 2 到 16 个字符', trigger: 'blur'}
                    ],
                    password: [
                        {required: true, message: '请输入密码', trigger: 'blur'},
                        {min: 4, max: 64, message: '长度在 4 到 64 个字符', trigger: 'blur'}
                    ],
                },
                items: {},
                intervalId: null
            }
        },
        beforeDestroy() {
            // 组件销毁前清除定时器，避免内存泄露
            if (this.intervalId) {
                clearInterval(this.intervalId);
            }
        },
        methods: {
            async submitForm() {
                this.loading = true
                try {
                    if(this.items && this.items.ticket){
                        this.ruleForm.ticket = this.items.ticket || ""
                    }
                    const resp = await this.$http.post('/auth/login', this.ruleForm)
                    if (resp.data.code !== 200) {
                        this.$message.error(resp.data.msg)
                    } else {
                        this.$store.commit('set_username', this.ruleForm.username)
                        this.$store.commit('set_token', resp.data.token)
                        this.$store.commit('set_openid', resp.data.openid)
                        this.$store.commit('set_userType', resp.data.userType)
                        if(resp.data.userType == "1") {
                            let type = localStorage.getItem('type') || 'doctor'
                            if(type == "doctor"){
                                await this.$router.push('/main/dashboard')
                            }else {
                                await this.$router.push('/company/dashboard')
                            }
                        }else if (resp.data.userType=="3" || resp.data.userType=="5") {
                            await this.$router.push('/main/dashboard')
                        }else if (resp.data.userType=="2" || resp.data.userType=="4") {
                            await this.$router.push('/company/dashboard')
                        }else {
                            this.$message.error("账号没有权限登录")
                        }
                    }
                } catch (e) {
                    this.$message.error(e.message)
                }
                this.loading = false
            },
            async getData() {
                this.loading = true
                const resp = await this.$http.get(`/wechat/qrcode`)
                if (!resp.data) {
                    this.$message.error(resp.data.msg)
                } else {
                    this.items = resp.data.data || {}
                }
                this.loading = false
            },
            async handleScanLogin() {
                const resp = await this.$http.get(`/wechat/qrcode/login?ticket=${this.items.ticket}`)
                if (resp.data.code === 201) {
                    if (this.intervalId) {
                        clearInterval(this.intervalId);
                    }
                    this.$message.error(resp.data.msg)
                    return
                }
                if (resp.data.code !== 200) {
                    // this.$message.error(resp.data.msg)
                } else {
                    this.$store.commit('set_username',  resp.data.name)
                    this.$store.commit('set_token', resp.data.token)
                    this.$store.commit('set_openid', resp.data.openid)
                    this.$store.commit('set_userType', resp.data.userType)
                    if(resp.data.userType == "1") {
                        let type = localStorage.getItem('type') || 'doctor'
                        if(type == "doctor"){
                            await this.$router.push('/main/dashboard')
                        }else {
                            await this.$router.push('/company/dashboard')
                        }
                    }else if (resp.data.userType=="3" || resp.data.userType=="5") {
                        await this.$router.push('/main/dashboard')
                    }else if (resp.data.userType=="2" || resp.data.userType=="4" ) {
                        await this.$router.push('/company/dashboard')
                    }else {
                        this.$message.error("账号没有权限登录")
                    }
                    if (this.intervalId) {
                        clearInterval(this.intervalId);
                    }
                }
            },
            init(){
                var that = this
                this.intervalId = setInterval(() => {
                    if(that.items && that.items.ticket){
                        that.handleScanLogin()
                    }
                    // 在这里执行你的代码
                }, 2000);
            }
        },
        mounted() {
            this.getData()
            this.init()
        }
    }
</script>


<style scoped>
    .page {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        display: flex;
        align-items: center;
    }

    .images {
        display: flex;
        align-items: center;
        justify-content: center;

    }

    .img {
        width: 200px;
        height: 200px;
        cursor: pointer;
    }

    .header,
    .banner,
    .footer {
        margin: 0 auto;
    }

    .header {
        padding: 20px;
    }

    .header .logo img {
        height: 80px;
    }

    .main {
        height: 300px;
        width: 100%;
        background-color: #2081c0;
        background-repeat: repeat;
    }

    .solution {
        position: absolute;
        /* border: 1px solid red; */
        width: 500px;
        text-align: center;
        top: 40px;
        left: 190px;
        color: #fff;
    }

    .solution h2 {
        color: #aadcff;
    }

    .form {
        border: 1px solid #8bbad5;
        border-top: 8px solid #4f9fe7;
        border-radius: 4px;
        background-color: white;
        width: 300px;
        padding: 20px;
    }

    .form h2 {
        margin: 5px 0 25px 0;
        color: #4c91f6;
        padding: 0;
        text-align: center;
    }

    .form .el-button {
        width: 100%;
    }

    .el-form-item--mini.el-form-item,
    .el-form-item--small.el-form-item {
        margin-bottom: 20px;
    }

    .footer {
        /* padding-top: 30px; */
        text-align: center;
        font-size: 13px;
        color: #CCCCCC;
        position: absolute;
        bottom: 20px;
        left: 0;
        right: 0;
    }

    .ms-login {
        position: absolute;
        left: 50%;
        top: 46%;
        /* width:350px; */
        margin: -190px 0 0 -175px;
        border-radius: 5px;
        /* background: white; */
        overflow: hidden;
    }

    .banner {
        width: 100%;
        height: 250px;
        background-color: #4c91f6;
        background-size: 100% 100%;
        background-repeat: repeat-x;
    }

    .banne-logo {
        width: 100%;
    }

    :deep(.el-input--small .el-input__inner) {
        height: 50px;
        line-height: 50px;
    }

    :deep(.el-input--small .el-input__icon) {
        line-height: 50px;
        font-size: 25px;
    }

    :deep(.el-input--prefix .el-input__inner) {
        padding-left: 40px;
        font-size: 14px;
    }

    .logo-svg {
        text-align: center;
        margin: 5px;
    }

    :deep(.el-button--medium) {
        padding: 15px 20px;
        font-size: 18px;
        border-radius: 4px;
        background-color: #4c91f6;
        margin-bottom: 20px;
    }
</style>
